<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1
      div.d-flex.flex-column.justify-start(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 2em;") QUÉ ES
          p(style="font-size: 14px; margin-bottom: 1em;") La dispersión de precios es la diferencia  entre el PVP mas bajo de una familia de productos y el PVP mas alto.
          p(style="font-size: 14px; margin-bottom: 1em;") A menor dispersión de precios, la probabilidad que el Ticket medio sea estable es mayor, y cuando esto sucede esta comprobado empíricamente que el Ticket medio real será mas bajo que el Ticket medio teórico y en consecuencia obtendremos menos ingresos.
          p(style="font-size: 14px; margin-bottom: 1em;") Por tanto, es muy importante que la dispersión entre el PVP mas bajo de una familia (price entry)  y el PVP mas alto (Price premium) no sea demasiado grande. Lo recomendable es que sea un máximo de un 30%.
          p(style="font-size: 14px;") Si ello no es posible, es recomendable entonces que  la dispersión venga mas por la diferencia  de PVP entre los PVP de rotación (productos de precio medio mas vendidos) y los PVP de los Price Premium, que no por que los price entry sean demasiado bajos.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 290px; display: flex; align-items: center;")
        Card_1_SVG_1_en
      div.d-flex.flex-column.justify-start(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 3em;") WHAT DOES IT MEAN
          p(style="font-size: 14px; margin-bottom: 2em;") Price dispersion is the difference between the lowest PVP of a product in any family and the highest PVP in the same family. The greater the Dispersion of prices, the greater the probability that the average Ticket is stable. It is empirically proven that the real average Ticket will be lower than the average arithmetic Ticket when this happens. In this case, then the SACI, and consequently we will obtain less income.
          p(style="font-size: 14px;") That is why the Dispersion between the lowest PVP of a family (price entry) and the highest PVP (Price premium) mustn't be too big. It is recommended 30%. If this is not possible, then the Dispersion comes more due to the difference in PVP between the rotation PVP (best-selling average price products) and the Price Premium PVP, not because of the price entry too low.

</template>

<script>
import Card_1_SVG_1 from "./assets/Card_1_SVG_1";
import Card_1_SVG_1_en from "./assets/Card_1_SVG_1_en";

export default {
  name: "Card_1",
  components: {Card_1_SVG_1, Card_1_SVG_1_en}
}
</script>

<style scoped>

</style>
